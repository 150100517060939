import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import _ from 'lodash';

const initial_state = {
  isValid: null,
  message: null,
  errors: {}
}

class Contact extends Component {
  state = _.extend({}, {
    isValid: null,
    message: null,
    errors: {}
  })

  render() {
    const siteTitle = _.get(this, 'props.data.site.siteMetadata.title');
    const success = _.get(this.state, 'isValid');

    const content = success ? this.successMessage() : this.contactForm();

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <div className="main banner">
          <h2 className="center" style={{ color: '#000' }}>Got a project? Or just want to drop us a line?</h2>
          <img style={{ width: '920px' }} src="/assets/img/elms/photoframe-email.jpg" />
          <div className="clear" />
        </div>

        {content}
      </Layout>
    );
  }

  contactForm = () => (
        <React.Fragment>
        <h2 className="pt0 pb0">Contact Us</h2>
        <p className="small">* denotes required fields</p>
        <form id="contact-form" action="/.netlify/functions/contact" onSubmit={this.handleSubmit} >
          <div id="info">
              {this.validationError('name')}
              <input name="name" type="text" className="required_field" id="name" placeholder="* Your Name" />

              {this.validationError('business')}
              <input name="business" type="text" id="business" placeholder="Business Name" />

              {this.validationError('email')}
              <input name="email" type="text" className="required_field" id="email" placeholder="* Your Email" />

              {this.validationError('phone')}
              <input name="phone" type="text" id="phone" placeholder="Phone Number" />

          </div>

          <div id="comments">
              {this.validationError('comment')}
              <textarea name="comment" placeholder="* Questions or Comments" className="required_field" rows="7" cols="40"></textarea>
          </div>

          <div class="small opt-in">
            <label class="small opt-in">
              {this.validationError('messaging-opt-in')}
              <input name="messaging-opt-in" type="checkbox" id="messaging-opt-in" />
              By submitting this form, I am providing my electronic signature expressly authorizing Design Magic Studios to contact me by email, text, or phone at the home or mobile phone number provided above.
              My information will be used solely for the purpose of direct communication with Design Magic Studios.
              My personal information will not be shared with third parties or affiliates, and will not be used for marketing or advertising purposes.
            </label>
          </div>

          <p className="center p20">
              <button className="large blue button" type="submit">Send Message</button>
          </p>
          {this.validationError('')}
          <input name="lastname" type="text" id="lastname" placeholder="Last Name" autoComplete="off" tabIndex="-1" />
        </form>
        </React.Fragment>
  );

  successMessage = () => (
    <div className='mail-sent'>
      <h2>Message Delivered</h2>
      <p>Thank you for contacting us. We will respond shortly.</p>
    </div>
  );

  validationError = (type) => {
    const error = _.get(this.state.errors, type);
    const classes = error ? 'active validation' : 'validation';
    return <span className={classes}>{error}</span>;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const serialized_data = new URLSearchParams(data).toString();

    fetch('/.netlify/functions/contact', {
      method: 'POST',
      body: serialized_data,
    })
    .then(response => response.json())
    .then(data => this.setState(_.extend({}, initial_state, data)));
  };
}

export default Contact;